<!--
 * @Description: 
 * @Author: fely
 * @Date: 2021-01-30 15:04:04
 * @LastEditTime: 2021-02-02 17:23:21
 * @LastEditors: fely
-->
<template>
  <div class="wrapper">
    <div class="header">
      <top-header></top-header>
      <div class="text-con">
        <h1>亿慧小秘书</h1>
        <span>www.gjyxms.com</span>
      </div>
    </div>
    <div class="content-contain">
    <div class="intro-wrapper">
      <div class="text-box">
        <p class="big-title">会员模块</p>
        <p class="small-title">Membership module</p>
        <p class="detail">个人用户和企业用户在使用亿慧小秘书app求职或招聘的过程中会得到成长值和积分，使用亿慧小秘书app的时间越长、频率越高得到的成长值和积分越多</p>
      </div>
      <div class="image-box">
        <img src="~@/assets/images/member1.png">
      </div>
    </div>
    <div class="intro-wrapper">
      <div class="image-box">
        <img src="~@/assets/images/member2.png">
      </div>
      <div class="text-box">
        <p class="big-title">权益详情</p>
        <p class="small-title">Details of interests</p>
        <p class="detail">成长值越高对应的会员等级越高，会员等级越高拥有的权益越多。比如个人较高等级的会员拥有置顶简历、竞争力分析以及更多的刷新简历次数的权益；企业较高等级的会员可以免费发布更多的职位、系统会推荐更多的人才、拥
        有更多的免费开聊次数、可以置顶职位等。</p>
      </div>
      
    </div>
    <div class="intro-wrapper">
      <div class="text-box">
        <p class="big-title">领积分</p>
        <p class="small-title">Leading integral</p>
        <p class="detail">使用积分可以兑换相应的权益，比如个人用户可以兑换刷新简历、置顶简历的权益；企业用户可以兑换职位置顶等权益。</p>
      </div>
      <div class="image-box">
        <img src="~@/assets/images/member3.png">
      </div>
    </div>
    <div class="footer con-foot">
      <p>©天津市亿慧网络科技有限公司 版权所有 <a class="beian"  target="_black" href="http://beian.miit.gov.cn">津ICP备20006513号</a></p>
      <div style="margin-left: 20px;">
        <a target="_blank" href="http://www.beian.gov.cn/"
          style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
          <img src="~@/assets/images/police.png" style="float:left;" />
          <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">津公网安备 12011102001059号</p>
        </a>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import TopHeader from './topHeader'
export default {
  name: '',
  data() {
    return {

    }
  },
  components: {
    TopHeader
  }
}
</script>

<style scoped lang="less">
@import '../styles/public.css';
@import '../styles/common.css';
.wrapper{
  height: 100%;
}
</style>
