<!--
 * @Description: 
 * @Author: fely
 * @Date: 2021-01-30 15:15:48
 * @LastEditTime: 2021-02-06 12:08:49
 * @LastEditors: fely
-->
<template>
  <div class="top-page">
    <div class="top" :class="white ? 'whiteBgColor' : ''">
      <div class="top-wrap">
        <div class="logo" :class="white ? 'blackColor' : ''">
          <router-link to="/index">
            <img src="~@/assets/images/logo.png" class="logo-img" />
            <span>亿慧小秘书</span>
          </router-link>
        </div>
        <div class="menus">
          <ul class="links" :class="white ? 'blackColor' : ''">
            <li><router-link to="/position">天天面试</router-link></li>
            <li><router-link to="/gjy">亿慧工匠园</router-link></li>
            <li><router-link to="/slip">工资条</router-link></li>
            <li><router-link to="/member">普通会员</router-link></li>
            <li><router-link to="/vip">VIP专区</router-link></li>
            <li><router-link to="/posList">信息发布</router-link></li>
          </ul>
          <div class="login-btn" :class="white ? 'blackColor' : ''">
            <template v-if="headImg && headImg != 'error'">
              <el-image
                fit="contain"
                @click="handleLogout"
                :src="headImg"
                style="height: 30px; width: 30px; border-radius: 50%"
              >
                <div slot="placeholder" class="image-slot">
                  <img
                    style="width: 30px; height: 30px"
                    src="~@/assets/images/userImg.png"
                    @click="handleLogout"
                  />
                </div>
                <div slot="error" class="image-slot">
                  <img
                    style="width: 30px; height: 30px"
                    src="~@/assets/images/userImg.png"
                    @click="handleLogout"
                  />
                </div>
              </el-image>
            </template>
            <template v-else>
              <span @click="handleClickLogin">注册登录</span>
            </template>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="loginBoxShow"
      width="400px"
      custom-class="my-dialog"
    >
      <div class="login-box">
        <div class="label-box">
          <span>欢迎登录</span>
        </div>
        <el-form ref="form" :model="form" :rules="rules">
          <el-form-item prop="phone">
            <el-input
              placeholder="请输入手机号"
              v-model="form.phone"
              style="margin-top: 50px"
              @change="handlePhone"
            >
              <template slot="prepend">+86</template>
            </el-input>
          </el-form-item>
          <el-form-item prop="veritycode">
            <el-input
              placeholder="短信验证码"
              v-model="form.veritycode"
              style="margin-top: 24px"
            >
              <template slot="suffix">
                <span v-show="!clickable">{{ cutdown }}s后重新发送</span>
                <span
                  v-show="clickable"
                  @click="getVerifyCode"
                  class="verfiy-code"
                  >发送验证码</span
                >
              </template>
            </el-input>
          </el-form-item>
          <el-form-item v-if="selectIdent" prop="is_companyadmin">
            <el-select
              v-model="form.is_companyadmin"
              placeholder="请选择身份"
              style="margin-top: 24px; width: 100%"
            >
              <el-option label="企业" value="1">企业</el-option>
              <el-option label="个人" value="0">个人</el-option>
            </el-select>
          </el-form-item>
          <div class="login-btn" @click="handleLogin">登录</div>
          <div class="other-remarks">未注册手机验证后自动注册</div>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { obtainCode, loginOut, isReg } from "@/api/login.js";
export default {
  name: "",
  components: {},
  data() {
    return {
      loginBoxShow: false,
      selectIdent: false,
      clickable: true,
      cutdown: 60,
      form: {},
      rules: {
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: /^1[3-9][0-9]{9}$/,
            message: "手机号格式不正确",
            trigger: "blur",
          },
        ],
        veritycode: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          { pattern: /^[0-9]{6}$/, message: "验证码错误", trigger: "blur" },
        ],
        is_companyadmin: [
          { required: true, message: "请选择身份", trigger: "blur" },
        ],
      },
      headImg: "",
    };
  },
  props: {
    white: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  created() {
    let userid = localStorage.getItem("userid");
    if (userid) {
      this.headImg = localStorage.getItem("userImg")
        ? localStorage.getItem("userImg")
        : "error";
    }
    this.$bus.on("showLogin", () => {
      console.log("showLogin");
      this.loginBoxShow = true;
    });
  },
  beforeDestory() {
    this.$bus.off("showLogin");
  },
  methods: {
    handlePhone(e) {
      isReg(e).then((res) => {
        if (!res.data) {
          this.selectIdent = true;
        } else {
          this.selectIdent = false;
        }
      });
    },
    handleClickLogin() {
      this.loginBoxShow = true;
    },
    handleLogout() {
      loginOut().then((res) => {
        if (res.code == 200) {
          localStorage.clear();
          // window.location.reload()
          this.$router.replace("/index");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      });
    },
    getVerifyCode() {
      this.$refs.form.validateField("phone", (err) => {
        if (!err) {
          obtainCode(this.form.phone).then((res) => {
            if (res.code == 200) {
              if (process.env.NODE_ENV === "development") {
                if (res.msg == "请求过于频繁") {
                  this.$notify({
                    message: "请求过于频繁",
                    duration: 2000,
                  });
                } else {
                  this.$notify({
                    title: "验证码",
                    message: res.data,
                    duration: 2000,
                  });
                }
              } else {
                this.$message.info("验证码已发送至手机");
              }

              let that = this;
              this.clickable = false;
              let timer = setInterval(() => {
                that.cutdown--;
                if (that.cutdown == 0) {
                  clearInterval(timer);
                  that.cutdown = 60;
                  that.clickable = true;
                }
              }, 1000);
            } else {
              this.$message.info("获取验证码失败");
            }
          });
        }
      });
    },
    handleLogin() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.form.isapp = false;
          this.form.xcx = 2;
          this.$store.dispatch("Login", this.form).then(() => {
            window.location.reload();
          });
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.whiteBgColor {
  background: #ffffff;
}
.blackColor {
  color: #333;
}
.top {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  // padding-left: 160px;
  height: 60px;
  color: #fff;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  .top-wrap {
    width: 980px;
    display: flex;
  }
  .blackColor {
    a {
      color: #333 !important;
    }
  }
  .logo {
    a {
      display: flex;
      align-items: center;
      color: #fff;
      font-weight: 600;
    }

    img {
      width: 20px;
      height: 20px;
    }
    span {
      margin-left: 13px;
    }
  }
  .menus {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding-right: 150px;
    box-sizing: border-box;
  }
  .links {
    margin-left: 70px;
    display: flex;
    align-items: center;
    li {
      margin-right: 40px;
    }
    a {
      color: #fff;
    }
  }
  .login-btn {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}
.login-box {
  height: 420px;
  width: 400px;
  background-color: #fff;
  padding: 36px 40px;
  box-sizing: border-box;
  .label-box {
    font-size: 18px;
    font-weight: 500;
    span {
      border-bottom: 2px solid #4e90ff;
    }
  }
  .login-btn {
    cursor: pointer;
    margin-top: 30px;
    width: 100%;
    height: 42px;
    background-color: #4e90ff;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 12px;
  }
  .other-remarks {
    margin-top: 30px;
    color: #aaaaaa;
    font-size: 14px;
    line-height: 24px;
  }
  .verfiy-code {
    color: #333;
    cursor: pointer;
  }
}
/deep/ .el-dialog.my-dialog {
  border-radius: 6px !important;
  overflow: hidden;
}
.el-form-item {
  margin-bottom: 0;
}
</style>

